






























import Vue from "vue";
import { VerifyEmail } from "@/interfaces/auth/verify_email";
import SubmitButton from "@/components/auth/SubmitButton.vue";
import { mapGetters, mapMutations } from "vuex";
import {
  GET_USER_DETAILS,
  RESET_PASS_USER_EMAIL,
  RESET_PASSWORD_PAYLOAD
} from "@/store/modules/auth/constants";
import router from "@/router";
import { ROOT_ERROR } from "@/store/modules/root/constants";
export default Vue.extend({
  name: "TokenVerification",
  components: { SubmitButton },
  data(): VerifyEmail & { email: string } {
    return {
      decoration_img_src: require("@/assets/illustrations/email.svg"),
      verification_code: "",
      is_valid: false,
      email: ""
    };
  },
  async created() {
    const code = this.$route.params.code;
    if (code) {
      const decode = atob(this.$route.params.code).split("_&_");
      if (decode.length === 2) {
        this.verification_code = decode[1];
        this.email = decode[0];
      } else {
        this.set_root_error(`${this.$t("auth.invalid-code")}`);
      }
    }
    if (code) {
      await this.submit_form();
    }
  },
  computed: {
    ...mapGetters("auth", {
      get_user_details: GET_USER_DETAILS,
      get_reset_pass_email: RESET_PASS_USER_EMAIL
    })
  },
  watch: {
    verification_code(new_value: string) {
      this.is_valid = /^[0-9]{4}$/.test(new_value);
    }
  },
  methods: {
    ...mapMutations("auth", {
      set_reset_pass_token: RESET_PASSWORD_PAYLOAD
    }),
    ...mapMutations({
      set_root_error: ROOT_ERROR
    }),
    async submit_form() {
      if (this.verification_code && this.verification_code.length === 4) {
        this.set_reset_pass_token({
          token: this.verification_code,
          email: this.email || this.get_reset_pass_email
        });
        await router.push("/auth/reset-password/create-new-password");
      }
    }
  }
});
